import {
  CreateInvoiceAndActBusinessInterface,
  InvoicesInterface,
} from '@features/invoices/domain/entities/invoices.interface';

export class LoadSilenceInvoices {
  static readonly type = '[Invoice] Load silence invoices';
}

export class LoadSilenceAllInvoices {
  static readonly type = '[Allinvoice] Load silence all invoices';
}

export class CreateInvoiceAndActBusiness {
  static readonly type = '[Invoice] Create invoice and act business';

  constructor(public payload: CreateInvoiceAndActBusinessInterface) {}
}

export class CreateInvoice {
  static readonly type = '[Invoice] Create invoice';

  constructor(public payload: InvoicesInterface) {}
}
