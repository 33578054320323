import {
  CreateInvoiceAndActBusinessInterface,
  InvoicesInterface,
} from '@features/invoices/domain/entities/invoices.interface';
import { Observable } from 'rxjs';

export abstract class InvoicesApiPort {
  abstract getInvoices(): Observable<InvoicesInterface[]>;

  abstract getAllInvoices(): Observable<InvoicesInterface[]>;

  abstract create(body: InvoicesInterface): Observable<InvoicesInterface>;

  abstract createInvoiceAndActBusiness(
    invoice: CreateInvoiceAndActBusinessInterface
  ): Observable<object>;
}
